.liveliness_start_button {
    background-color: #C1FFFD;
    border-radius: 10px;
    padding: 20px 5px;
    font-family: GooglesansMedium;
}
.liveliness_start_button h1 {
    font-family: GooglesansRegular;
    font-size: 50px;
    color: #15605D;
    margin-bottom: -10px;
    text-align: center;
}
.liveliness_video{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 8px #425E5D33;
    /* padding:25px; */
    text-align: center;
    font-family: GooglesansBold;
    border-radius: 10px;
}
.liveliness_button {
    width: 100%;
    margin-top: 12px !important;
}
.liveliness_webcam video {
    width: 100%;
    border-radius: 10px;
}
.round-tool-icon {
    background: red !important;
}
.round-tool-icon-pressed{
    border: 2px solid black;
    background: gray !important;
}

#container1 {
    position:relative;
    display: flex;
    justify-content: center;
  }
  
 
  #playVideo {
    position: absolute;
    top: 80%;
    z-index: 800;
  }
.main_liveliness .css-79elbk {
    margin-top: -16px;
}
.css-42igfv {
    font-family: GooglesansBold;
}