#consent_page h1 {
  font-size: 40px;
  font-family: GooglesansBold;
  padding-top: 25px;
}
.consent_data {
  text-align: justify;
}
.consent_data ul {
  list-style: none;
  font-family: GooglesansRegular;
  padding-left: 35px !important;
}

.consent_data ul li::before {
  content: "\2022";
  color: #048C88;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-size: 22px;
}
.consent_button {
    width: 100%;
    font-size: 16px;
    font-family: GooglesansMedium;
}
.checkbox p {
    font-family: GooglesansRegular;
}

.cursor-p {
  cursor: pointer;
}
.context {
  font-family: GooglesansRegular;
}
