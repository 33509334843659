
  .inputStyle {
    width: 100% !important;
    font-size: 2rem;
    margin-left: 5px;
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 2px solid #59ACAA;
    background-image: none !important;
  }
  #__lpform_input_idx_0_icon {
    display: none;
  }

  .error {
    border: 1px solid red !important;
  }
.otp_main {
    padding: 60px 10px;
    border-radius: 10px;
}
.otp_main h1 {
    font-family: GooglesansBold;
    font-size: 40px;
    margin-top: 30px;
    margin-bottom: 30px;
}
.otp_main h6 {
    font-family: GooglesansRegular;
    font-size: 14px;
    color: #484848;
}

.footer {
    display: flex;
    justify-content: space-between;
}
.otp_submit_button button {
  font-family: GooglesansBold !important;
  text-transform: none;
  font-size: 16px !important;
}
.otp_button_div button {
  width: 100% !important;
  font-family: GooglesansBold;
}
.background-white {
  background-color: white !important;
}
.resend {
  color: #00716e;
  font-family: GooglesansBold;
  cursor: pointer;
}


.data button span{
  color: #00716e;
  font-size: 15px;
  font-family: GooglesansBold;
  text-transform: capitalize;
  margin-top: -6px;

}
.data {
  font-family: GooglesansRegular;
}
.timer {
  font-size: 13px;
  color: black;
  text-align: center;
  font-family: GooglesansRegular;
  margin-top: 10px;
  margin-bottom: -13px;
}