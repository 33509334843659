.aadharxml_data {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 8px #425E5D33;
    margin: 12px;
    padding: 10px;
    border-radius: 6px;
    cursor: pointer;
}
.aadhar_div {
    font-size: 20px;
    font-family: GooglesansRegular;
    color: #191919;
}
.aadharxml_data p {
    font-size: 14px;
    font-family: GooglesansRegular;
    color: #484848;
}


.roatate-90 { 
    transform: rotate(90deg);
}