.header {
    background-color: #0F918D;
    color: white;
    padding: 7px;
}
.header  {
    font-family: GooglesansBold;
    font-size: 20px;
}
.header p {
    font-family: GooglesansRegular;
    margin-bottom: 0px;
}
.MuiDialog-paper {
    margin: 0px !important;
}
.logout_popup .MuiTypography-h6   {
    font-family: GooglesansBold !important;
}
.logout_popup .MuiDialogContentText-root {
    font-family: GooglesansRegular;
    color: black;
}
.logout_popup .MuiDialogActions-root {
    display: flex;
    justify-content: space-around;
}
.logout_popup .MuiDialog-paperScrollPaper {
    border-radius: 25px;
}
.logout_popup .MuiButton-root {
    color: #1e90ff !important;
    text-transform: none;
    font-size: 17px;
}