@font-face {
  font-family: ProximaNova;
  src: url(../../fonts/Google-Sans-Font/ProximaNova-Regular.ttf);
}

@font-face {
  font-family: ProximaNovaSB;
  src: url(../../fonts/Google-Sans-Font/ProximaNova-Semibold.ttf);
}

@font-face {
  font-family: ProximaNovaB;
  src: url(../../fonts/Google-Sans-Font/ProximaNova-Bold.ttf);
}

a {
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
}

.wrapper {
  /* display: flex;
    align-items: stretch; */
  /* padding: 30px; */
  margin-left: 60px !important;
  /* overflow-x: hidden !important; */
  /* margin-top: 50px; */
}

.main {
  font-family: ProximaNova;
  background-color: #e2eaea !important;
  min-height: 100vh !important;
}

.row {
  max-width: 100% !important;
  margin: 0% !important;
}

#invoicesHead {
  height: 64px;
  background-color: #ffffff;
  width: 100% !important;
  box-shadow: 0px 2px 3px #00000029;
  position: relative;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#invoicesHead button {
  height: 34px;
  width: 115px;
  align-items: center;
  margin-right: 30px;
}

#invoicesHead h5 {
  font-size: 18px;
  padding: 21px;
  padding-left: 29px !important;
  font-family: ProximaNovaSB;
  color: #2c2c2c;
  margin: 0px !important;
  /* width: fit-content; */
}

#welcomeDiv {
  background-color: white !important;
  height: 90px;
  width: 97%;
  margin: 20px !important;
  border-radius: 4px !important;
  padding: 18px !important;
  position: relative;
  display: flex;
  justify-content: space-between;
}

#welcomeDiv #welcomeText {
  margin-left: 30px !important;
  /* margin-top: 5px !important; */
  margin-right: 240px !important;
}

#welcomeDiv h5 {
  font-size: 18px;
  font-family: GooglesansMedium;
  /* color: #2C2C2C; */
}

#welcomeDiv h6#dot {
  margin-right: 15px !important;
  margin-left: 15px !important;
  font-size: 16px !important;
  color: rgb(168, 164, 164);
}

#callsRow {
  width: 97%;
  margin: 20px !important;
  margin-top: 0px !important;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 20px;
  /* grid-row-gap: 20px; */
  align-content: space-evenly;
  /* padding: 10px; */
}

#callsCol {
  background-color: white;
  height: 80px;
  border-radius: 4px !important;
  padding: 15px !important;
  padding-left: 30px !important;
  position: relative;
}

#callsCol #col1 {
  background-color: rgb(210, 248, 247);
  width: 50px;
  height: 50px;
  border-radius: 25px;
}

#callsCol #col1 #phone {
  color: rgb(49, 153, 150);
  font-size: 25px !important;
  margin: 14px;
}
#callsCol #col1 #exclamation {
  color: rgb(49, 153, 150);
  font-size: 25px !important;
  margin: 14px;
  margin-left: 20px !important;
}

#callsCol #col2 {
  margin-left: 30px;
}

#callsCol #col2 h5 {
  font-size: 18px;
  font-family: ProximaNova;
  color: #2c2c2c;
  margin-bottom: 0px !important;
  margin-top: 5px !important;
}

#callsCol #col2 h6 {
  font-size: 16px;
  font-family: ProximaNova;
  color: rgb(136, 134, 134);
  margin-bottom: 0px !important;
  margin-top: 5px !important;
}

#callsCol #col3 {
  position: absolute;
  right: 40px !important;
}

#callsCol #col3 h4 {
  font-size: 26px;
  font-family: ProximaNova;
  color: #2c2c2c;
  margin-top: 10px !important;
}

#tablediv {
  margin: 20px;
}

#tablediv h5 {
  font-size: 18px;
  font-family: ProximaNovaSB;
  color: #2c2c2c;
}

#toReview .MuiTableRow-head {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

#toReview .MuiTableCell-body {
  color: #2d2e30;
  font-size: 14px;
  /* padding-left: 25px !important; */
  background-color: white !important;
}

#toReview .MuiTableCell-head {
  background-color: #4ac8c4 !important;
  color: white !important;
  font-family: ProximaNovaSB !important;
  font-size: 16px !important;
  /* padding-left: 25px !important; */
}

#toReview .MuiCheckbox-root {
  color: #b9bee1 !important;
}

#toReview .MuiCheckbox-root path {
  background-color: white !important;
}

#toReview .MuiTableCell-body .noDataDisp {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

/* #toReview .MuiTableCell-body .noDataDisp img {
    height: 100px;
    width: 100px;
} */

#toReview .MuiIconButton-label input {
  background-color: white !important;
}

#toReview .MuiTableCell-body h5 {
  font-size: 28px !important;
  font-family: ProximaNovaSB !important;
  margin-bottom: 10px !important;
}

#toReview .MuiTableCell-body p {
  font-size: 15px !important;
  margin-bottom: 0px !important;
  line-height: 20px !important;
}

#toReview .upload_doc_button_table {
  border: none;
  width: 337px;
  height: 48px;
  /* border-radius: 20px; */
  background-color: #048c88;
  color: white;
  font-family: ProximaNovaSB;
  font-size: 16px;
  margin-top: 10px;
}

#toReview .MuiPaper-elevation1 {
  display: none !important;
}

#toReview .MuiSvgIcon-root {
  fill: #37b4de !important;
}

#toReview .MuiTableCell-head .MuiSvgIcon-root {
  fill: white !important;
}

#borderBox1 {
  margin: 40px;
  width: 1048px;
  height: 400px;
  border: 4px dashed #d5d3d3;
  overflow-y: auto;
}

.fade {
  transition: none !important;
}

.uploadMod.modal.show .modal-dialog {
  max-width: 1220px !important;
  /* height: 600px !important; */
}

.uploadMod .modal-content {
  width: 50%;
  margin: auto;
  border-radius: 10px !important;
  text-align: center;
  padding: 20px;
}
.uploadMod .modal-content input {
  width: 69%;
  height: 35px;
}
.uploadbottom_data button {
  float: right;
  border: 1px solid red;
}

.filepond--drop-label.filepond--drop-label label {
  font-family: ProximaNovaSB !important;
  font-size: 20px !important;
  color: #404040 !important;
}

.filepond--label-action {
  padding: 9px 36px !important;
  margin-left: 15px !important;
  box-shadow: 0px 3px 6px #00000029;
  text-decoration: none !important;
  font-size: 18px !important;
  background-color: #f8f8f8 !important;
}

#batch {
  height: 74px !important;
  background-color: #f3f3f3 !important;
}

.form-group {
  padding-top: 20px !important;
  color: #404040 !important;
  font-family: ProximaNova !important;
  font-size: 18px;
  justify-content: center !important;
}

.col-sm-2 {
  -ms-flex: 0 0 10.666667%;
  flex: 0 0 10.666667%;
  max-width: 10.666667%;
}

.uploadMod .modal-footer {
  background-color: #cbcbcb !important;
  justify-content: center !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.uploadMod .modal_cancel_button {
  border: none;
  width: 90px;
  height: 34px;
  /* border-radius: 20px; */
  background-color: #535353 !important;
  color: white;
  font-family: ProximaNovaSB;
  font-size: 16px;
  margin-right: 10px !important;
}

.uploadMod .modal_digitize_button {
  border: none;
  width: 200px;
  height: 34px;
  /* border-radius: 20px; */
  background-color: #048c88 !important;
  color: white;
  font-family: ProximaNovaSB;
  font-size: 16px;
  margin-left: 10px !important;
}

#invoicesNav .export_button {
  border: none;
  width: 108px;
  height: 34px;
  border-radius: 4px;
  background-color: #048c88;
  color: white;
  font-family: ProximaNovaSB;
  font-size: 16px;
  right: 29px;
  position: absolute;
  margin-top: -10px;
}

.blue {
  color: #086968 !important;
  cursor: pointer;
  /* font-weight: 600; */
}

.MuiTable-root.MUIDataTable-tableRoot-14 {
  margin: 0px !important;
}

#search {
  width: 25%;
  margin-left: 20px !important;
}

#search_text {
  width: 67%;
  margin-left: 20px !important;
  position: relative;
  height: 36px;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}

#searchButton {
  width: 15%;
  margin-left: 20px !important;
  position: relative;
  height: 38px;
  background-color: #048c88;
  border-color: hsl(0, 0%, 80%);
  color: #ffffff;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}

#stopButton {
  margin-top: -8px;
  width: 150px;
  height: 35px;
  background-color: #048c88;
  border-radius: 4px;
  right: 50px;
  border: none;
  text-align: center;
  font-size: 16px;
  color: #ffffff;
  position: absolute;
  /* padding: 5px; */
}

#startButton {
  margin-top: -8px;
  width: 150px;
  height: 35px;
  background-color: #048c88;
  border-radius: 4px;
  border: none;
  text-align: center;
  font-size: 16px;
  color: #ffffff;
  position: absolute;
}
.dashboard_button {
  width: 225px !important;
  margin-left: 20px !important;
  height: 40px;
  margin-top: 0px !important;
}
.welcomeuser {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.company_input {
  display: flex;
  justify-content: flex-end;
}
.agent_name {
  font-family: GooglesansBold;
  font-size: 18px;
}
.agent_name_div {
  padding-left: 20px;
}
.main .css-79elbk {
  margin: 0px !important;
}
.MuiTableFooter-root {
  display: none !important;
}

.tableDetails {
  background-color: white;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.MuiPaper-elevation4 {
  box-shadow: none !important;
}
.pagination {
  display: flex;
  justify-content: flex-end;
}

.textInputforcreate {
  padding: 6px;
  margin: 10px 26px;
  border-radius: 5px;
  cursor: pointer;
  border: 2px solid;
}
.paginationbutton {
  height: 36px !important;
  padding: 17px 35px !important;
  margin: 10px 10px !important;

}
.dashboardheader {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.dashboardheader h3 {
  font-size: 22px;
  font-family: GooglesansMedium;

}
.hideimage {
  width: 40px;
  cursor: pointer;
}
.que_length{
  font-size: 19px;
  font-family: GooglesansMedium;

}