body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #edf2f2 !important; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: GooglesansBold;
  src: url(./fonts/Google-Sans-Font/GoogleSans-Bold.ttf);
}
@font-face {
  font-family: GooglesansMedium;
  src: url(./fonts/Google-Sans-Font/GoogleSans-Medium.ttf);
}
@font-face {
  font-family: GooglesansRegular;
  src: url(./fonts/Google-Sans-Font/GoogleSans-Regular.ttf);
}

.css-79elbk {
  position: inherit !important;
}
input {
  font-family: GooglesansRegular !important;
}
.Toastify__toast-body {
  font-family: GooglesansMedium;
}
.opentok_data {
  padding-bottom: 100px;
}