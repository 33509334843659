@font-face {
  font-family: ProximaNova;
  src: url(../../fonts/Google-Sans-Font/ProximaNova-Regular.ttf);
}
@font-face {
  font-family: ProximaNovaB;
  src: url(../../fonts/Google-Sans-Font/ProximaNova-Bold.ttf);
}

#signUpBody {
  width: 100%;
  /* padding: 20px; */
}

#signUpForm h1 {
  color: #3a3a3a;
  font-size: 21px;
  text-align: center;
  font-weight: 600;
  padding-top: 13px;
  padding-bottom: 12px;
  font-family: "Open Sans", sans-serif;
}

#email {
  display: block;
}

.textInput {
  height: 45px;
  width: 100%;
  border: solid 1px #dadada;
  padding: 0 20px;
  margin-bottom: 7px;

}

.fa-eye {
  color: #c1c7d0;
}

#signUpButton {
  display: block;
  height: 32px !important;
  width: 100%;
  color: white;
  background-color: #00716e;
  border: none;
  border-radius: 4px;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  margin-top: 10px;
}

select {
  background-color: white !important;
}
.signup_form {
  width: 100%;
  margin: auto;
}
.signup_form label {
  margin-bottom: 0px !important;
    font-family: GooglesansMedium;
    font-size: 14px;
    margin-top: 8px;
}
@media only screen and (max-width: 600px) {
  .register-hr {
    width: 50px !important;
  }
}

.errormsg {
  font-size: 13px;
  color: red;
  font-family: GooglesansMedium;
  margin-bottom: 15px;
}
.login_error {
  color: red;
  text-align: center;
  padding-bottom: 12px;
}
.login_success {
  color: green;
  text-align: center;
  padding-bottom: 12px;
}
.hide {
  width: 26px;
  cursor: pointer;
  position: absolute;
  margin-top: -36px;
}

.hide_div {
  display: flex;
  justify-content: flex-end;
}
.signUpForm {
  width: 100%;
}
#data {
  width: 100%;
}
label + .MuiInput-formControl  {
  margin-top: 0px !important;
}
.MuiInputLabel-formControl {
  display: none !important;
}