.waitpage {
  font-family: GooglesansBold;
  font-size: 40px;
  padding-top: 70px;
  text-align: initial;
}
.waitpage p {
  font-family: GooglesansRegular;
  font-size: 15px;
  padding-top: 40px;
}
.waittime_counter {
  font-family: GooglesansRegular;
  background-color: #23c8c3;
  padding: 30px;
  color: white;
  text-align: center;
  border-radius: 10px;
  margin-top: 35px;
}
.waittime_counter span {
  font-size: 16px;
}
.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  margin-top: 30px;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
.waitpage_button {
    width: 100%;
}