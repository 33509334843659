@font-face {
    font-family: ProximaNova;
    src: url(../../../fonts/Google-Sans-Font/ProximaNova-Regular.ttf);
    }
    


#sidebar {

    min-width: 190px;
    max-width: 190px;
    width: 60px;
    height: 100vh;
    box-shadow: 0 0 20px 0 rgba(8, 9, 9, 0.15);
    background-color: #048C88;
    color: #fff;
    transition: all 0.3s;
    position: fixed;
}

#sidebar.activexyz {
    /* transition: 0.3s; */
    min-width: 60px;
    max-width: 60px;
    text-align: center;
    background: #048C88; 
}



#sidebar.activexyz p {
    display: none;
}

ul {
    list-style-type: none;
    margin: 0px !important;
    padding: 0px !important;
}


#sideNavTop {
    margin-top: 10px !important;
    /* position: fixed; */
}

#sideNavTop li {
    margin-top: 0px !important;
    /* border-bottom: 1px solid gray; */
}
#sideNavTop #logoLi {
    margin-top: 0px !important;
}


#sideElements, #sideElements1, #sideElements2, #sideElements3, #sideElements4 {
    padding: 0px;
    width: inherit;
    height: 55px;
    border-radius: 0px;
}

#sidebar.activexyz #sideElements:hover {
    
    background-color: #0F5755;
    cursor: pointer;
    border-left: solid 3px #48C8C5;
}
#sidebar.activexyz #sideElements1:hover {
    
    background-color: #0F5755;
    cursor: pointer;
    border-left: solid 3px #48C8C5;
}
#sidebar.activexyz #sideElements2:hover {
    
    background-color: #0F5755;
    cursor: pointer;
    border-left: solid 3px #48C8C5;
}
#sidebar.activexyz #sideElements3:hover {
    
    background-color: #0F5755;
    cursor: pointer;
    border-left: solid 3px #48C8C5;
}
#sidebar.activexyz #sideElements4:hover {
    
    background-color: #0F5755;
    cursor: pointer;
    border-left: solid 3px #48C8C5;
}

#sidebar.activexyz .backColor {
    background-color: #0F5755;
    cursor: pointer;
    border-left: solid 3px #48C8C5;
}

#sideElements p, #sideElements1 p, #sideElements2 p, #sideElements3 p, #sideElements4 p {
    font-size: 14px;
    color: white;
    padding-left: 10px;
    padding-top: 2px;
    font-family: ProximaNova;
    text-align: left;
    margin-left: 25px;
}


#sidebar.activexyz .logo {
    background-image: url(../../../Images/IN-D-FS-AppLogo.png);
    background-repeat: no-repeat;
    width: 42px;
    height: 36px;
    margin: 3px !important;
    margin-bottom: 8px !important;
    display:inline-block;
}



#sidebar.activexyz .Dashboard {
    background-image: url(../../../Images/dash.png);
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    margin: 15px !important;
    display:inline-block;
}


#sidebar.activexyz .Settings {
    background-image: url(../../../Images/settings.svg);
    background-repeat: no-repeat;
    width: 26px;
    height: 31px;
    margin: 17px !important;
    display:inline-block;
}

#sideNavBottom {
    /* margin-top: 170px; */
    margin-bottom: 0px !important;
    bottom: 0px !important;
    position: fixed;
    width: inherit;
}

#sideNavBottom li {
    margin-bottom: 20px;
}

#sidebar.activexyz .Information {
    background-image: url(../../../Images/information.svg);
    background-repeat: no-repeat;
    width: 26px;
    height: 31px;
    margin: 15px !important;
    display:inline-block;
}

#sidebar.activexyz .Logout {
    background-image: url(../../../Images/logout.svg);
    background-repeat: no-repeat;
    width: 24px;
    height: 24px; 
    margin: 15px !important;
    display:inline-block;
}


#sidebar #collapseIcon {
    bottom: 0;
    background-color: #005A56;
    height: 45px;
    width: 190px;
    cursor: pointer;
    /* background-color: #BFF2F1; */
    transition: 0.3s;
}

#sidebar .openIcon {
    color: white;
    font-size: 25px;
    font-weight: bold;
    padding-top: 5px !important;
    padding-bottom: 3px !important;
    /* padding-left: 150px; */
    position: fixed;
    margin-left: 150px;
    /* transition: 0.1s; */
    transform: rotate(180deg);
}



#sidebar.activexyz #collapseIcon {
    bottom: 0;
    background-color: #005A56;
    height: 45px;
    width: 60px;
    cursor: pointer;
}

#sidebar.activexyz .openIcon {
    color: white;
    font-size: 25px;
    font-weight: bold;
    padding-top: 5px;
    
    transform: none;
    /* transition: 1s; */
    position: fixed;
    margin-left: 23px !important;
}


#sidebar.activexyz #tool {
    display: none;
} 

.styles_modal__gNwvD {
    min-width: 500px;
    min-height: 300px;
    margin: 300px 700px;
}


#logoutButton {
    display: block;
    height: 35px;
    width: 75px;
    background-color: #068D87;
    border: none;
    border-radius: 4px;
    color: white;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 5px;
    letter-spacing: 0.1rem !important;
    margin-right: 20px;
}


.noDisp {
    display: none !important;
}


/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
    #sidebar {
        min-width: 80px;
        max-width: 80px;
        text-align: center;
        margin-left: -80px !important;
    }
    .dropdown-toggle::after {
        top: auto;
        bottom: 10px;
        right: 50%;
        -webkit-transform: translateX(50%);
        -ms-transform: translateX(50%);
        transform: translateX(50%);
    }
    #sidebar.activexyz {
        margin-left: 0 !important;
        /* background-color: #fff; */
    }
    #sidebar .sidebar-header h3, #sidebar .CTAs {
        display: none;
    }
    #sidebar .sidebar-header strong {
        display: block;
    }
    /* #sidebar ul li a {
        padding: 20px 10px;
    } */
    /* #sidebar ul li a p:hover{
       color: #39ac91;
    }
    #sidebar ul li a p:focus{
        color: #39ac91;
     } */
    #sidebar ul li a span {
        font-size: 0.85em;
    }
    #sidebar ul li a i {
        margin-right: 0;
        display: block;
    }
    /* #sidebar ul ul a { */
        /* padding: 10px !important; */
    /* } */
    #sidebar ul li a i {
        font-size: 1.3em;
    }
    #sidebar {
        margin-left: 0;
    }
    #sidebarCollapse span {
        display: none;
    }
}