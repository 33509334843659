.signup {
    font-family: GooglesansBold;
    padding: 10px 0px 20px 0px;
}
.signup_form_user label {
    margin-bottom: 0px !important;
    font-family: GooglesansMedium;
    font-size: 14px;
    margin-top: 8px;
} 
.text_information {
    font-size: 13px;
    font-family: GooglesansRegular;
}
.underline {
    width: 100% ;
}
#standard-name {
    border-bottom: 2px solid #59ACAA !important;
}
#standard-name3 {
    border-bottom: 2px solid #59ACAA !important;
}
#standard-name2 {
    border-bottom: 2px solid #59ACAA !important;
}
#standard-name1 {
    border-bottom: 2px solid #59ACAA !important;
}
#standard-names {
    border-bottom: 2px solid #59ACAA !important;
}
.css-79elbk {
    margin: 0px -15px;
    padding-bottom: 1px;
}

#signUpButton:disabled {
    background-color: grey;
}
.data .css-79elbk {
    height: 100vh;
}
.MuiMenuItem-root {
    font-family: GooglesansMedium !important;
}