.otp_box {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 8px #425E5D33;
    padding: 50px 25px;
    text-align: center;
    font-family: GooglesansBold;
    border-radius: 10px;
    margin: 16px;

}
.otp_button button{
    width: 100%;
}
.otp_button {
    margin: 16px;

}
.otp_box button span {
    color: #0F918D;
    font-size: 20px;
    font-family: GooglesansMedium;
}
#standard-name {
    border-bottom: 2px solid #59ACAA !important;
}
.aadharotp_label {
    display: flex;
    margin: 20px 0px -20px 0px;
    color: #484848;
    font-family: GooglesansMedium;
    font-size: 14px;
}
.otp_label{
    margin: 0px;
}
.aadhar_error {
    color: red;
    font-size: 12px;
    float: left;
}