
.loader-wrapper {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    width: inherit;
  }
  .inside_loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 35%;
    position: fixed;
    font-family: GooglesansMedium;
    padding: 12px;

  }
  .inside_loader p {
    text-align: center;
  }
  .logout {
    cursor: pointer;
  }
  @media only screen and (max-width: 600px) {
    .inside_loader {
      top: 50%;
    }
  }