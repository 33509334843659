.aadhar_extraction h4{
    padding-top: 15px;
    font-family: GooglesansMedium;
    font-size: 27px;
    text-align: center;
    margin: 10px 0px 20px 0px;
}
.extraction_data {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 8px #425E5D33;
    padding: 18px;
    border-radius: 10px;
}
.extraction_data img {
    width: 61px;
    height: 61px;
    border-radius: 50%;
    margin-right: 20px;
}
.extraction_profile_data {
    padding: 10px ;
}
.extraction_data h6 {
    font-family: GooglesansMedium;
}
.extraction_data h5 {
    font-family: GooglesansRegular;
    font-size: 17px;
}
.current_address {
    color: #0F918D;
    font-family: GooglesansBold;
    cursor: pointer;
}
.aadhar_extraction .MuiOutlinedInput-input {
    padding: 10px;
}
.aadhar_extraction button {
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
}
.aadhar_extraction p {
    margin-bottom: -12px;
}
.current_address img {
    width: 30px;
    margin-bottom: 10px;
}
.aadhar_extraction form {
    font-family: GooglesansRegular;
}