.aadhar_back {
    display: flex;
    align-items: baseline;
    padding-top: 20px;
    font-family: GooglesansMedium;
    cursor: pointer;
}
.aadhar_back img {
    width: 23px;
    margin-right: 10px;
}
.aadharxmlupload_form p{
    text-align: center;text-decoration: none !important;
    font-size: 20px;
    font-family: GooglesansRegular;
    color: #535353;
    padding-top: 1.5rem;
}
.aadharxmlupload_form {
    margin: 10px;
}
.aadhar_sharecode {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 8px #425E5D33;
    padding: 50px 25px;
    text-align: center;
    font-family: GooglesansBold;
    border-radius: 10px;

}
.aadharuploadinput {
    width: 100% ;
    
}

#standard-basic {
    border-bottom: 2px solid #59ACAA !important;
}
.filepond--drop-label {
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border-radius: 10px;
    box-shadow: 0px 2px 8px #425E5D33;
    padding: 88px 0px;

}
.filepond--drop-label label {
    border: 2px dashed #0F918D !important;
    display: flex !important;
    flex-direction: column !important;
    padding: 12px 50px !important;
    border-radius: 10px;
    cursor: pointer !important;
    background-color: #F3FFFF;

}
.filepond--drop-label label span {
    color: #007773;
    font-family: GooglesansBold;
    font-size: 20px;
}
.aadharxmlupload_form a {
    text-decoration: underline !important;
    color: #0F918D;
    font-family: GooglesansBold;

    
}
.submit_button button {
    width: 100%;
}
.filepond--panel-center {
    background-color: white !important;
    border-radius: 10px !important;
}
.filepond--file {
    background-color: grey;
}
