.captured_image_show {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 8px #425E5D33;
    width: 100%;
    height: 206px;
    text-align: center;
    font-family: GooglesansBold;
    border-radius: 10px;
}
.captured_image_show h6 {
    font-size: 16px;

}
.capture_image {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 8px #425E5D33;
    padding: 15px 15px;
    border-radius: 10px;

}
.capture_button {
    width: 100%;
}
.image_capture_button {
    margin: auto !important;
    display: flex !important;
    margin-bottom:10px !important;

}
.image_show img{
    width: 100%;
    border-radius: 6px;
    height: 280px;
}
.video_show video {
    border-radius: 6px;
    width: 100%;
}
.video_show {
    margin-bottom: 10px;
}
.status {
    background-color: #CCFFFE;
    padding: 13px;
    margin: 10px 0px;
    border-radius: 10px;
  font-family: GooglesansMedium;
  text-align: center;

}