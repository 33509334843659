.user_data_results {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 8px #425E5D33;
    text-align: center;
    font-family: GooglesansMedium;
    border-radius: 10px;
    padding: 10px;
    margin-top: 25px;
}
#profile_icon {
    width: 77px;
    border-radius: 50%;
    margin-right: 18px;
    height: 81px;
}
.profile_data {
    display: flex;
    align-items: center;
    text-align: -webkit-auto;
}
.profile_data h6 {
    font-family: GooglesansRegular;
}
.profile_data h3 {
    font-family: GooglesansMedium;
}
.profile_submit {
    width: 100%;
}
.profile_details h4 {
    background-color: #BAEFED;
    padding: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.profile_details {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 8px #425E5D33;
    font-family: GooglesansMedium;
    border-radius: 10px;
    /* padding: 10px; */
    margin-top: 10px;
}
.mini_profile{
    padding: 10px;
}
.mini_profile h5{
    font-size: 14px;
}
.mini_profile p {
    font-size: 12px;
    font-family: GooglesansRegular;
    color: #364848;
}
hr {
    margin-bottom: 7px;
}
.icon_data h6 {
    font-family: GooglesansMedium;
    font-size: 14px;
}
.profile_image img {
    width: 24px;
}
.profile_loader {
    margin-top: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;

}
.main_profile .css-df17o1 {
    background-color: white !important;
    height: 107%;
    margin-top: -20px;
}
.main_profile .css-42igfv {
    color: black !important;
    margin-top: 84%;
}
.main_profile .css-50etie svg circle {
    stroke :black !important
}
.main_profile .css-50etie {
    width: auto;
}
.orange_logo {
    width: 20px;
}
.orange_div {
    display: flex;
    align-items: center;
}
.orange_div h5 {
    margin-bottom: 0px;
}
.profilelocation{
    width:70%;
    margin-left:auto

}