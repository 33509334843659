@font-face {
    font-family: proximaNovaBold;
    src: url(../../fonts/Google-Sans-Font/ProximaNova-Bold.ttf);
  }
@font-face {
font-family: googlesans;
src: url(../../fonts/Google-Sans-Font/GoogleSans-Regular.ttf);
}
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap");

.row {
    max-width: 100% !important;
    margin: 0% !important;
}
#loginHead h1 {
    color: #565656;
    font-size: 50px;
    font-weight: bold;
    padding-top: 15px;
    text-align: center;
    font-family: ProximaNovaB;
}

#loginBody {
    width: 100%;
    /* padding: 20px; */
}
.loader {
    padding-top: 25px;
}
#loginForm h5 {
    color: #3a3a3a;
    text-align: center;
    padding-top: 25px;
    padding-bottom: 20px;
    font-size: 21px;
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
}
#loginForm {
    width: 100%;
}



.textInput {
    height: 45px;
    width: 100%;
    border: solid 1px #dadada;
    padding: 0 20px;
}

.field-icon {
    margin-left: -40px;
    cursor: pointer;
}

.fa-eye {
    color : #c1c7d0;
}

#signInButton {
    display: block;
    height: 34px;
    width: 100%;
    color: #fff;
    background-color: #00716e;
    border: none;
    border-radius: 4px;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    margin-top: 30px;
    font-family: GooglesansMedium;

    
}



input[type="checkbox"] {
    height: 20px;
    width: 20px;
    background-color: white !important;
}

#newFull {
    margin-top: 10px !important;
}

#new {
    color: #7d7d7d;
    text-align: center;
    font-family: GooglesansMedium;
    position: relative;
    margin: 0px !important;
    padding: 5px 0px !important;
}

hr {
    background-color: #d1d1d1;
}

#createButton {
    display: block;
    height: 32px;
    width: 100%;
    background-color: white;
    border: solid 1px #e2e2e2;
    border-radius: 4px;
    color: #068D87;
    text-align: center;
    font-size: 15px;
    margin-top: 10px;
    transition: 0.2s;
    font-weight: bold;
    font-family: "Open Sans", sans-serif;
}

#createButton:hover {
    background-color: #00716e;
    color: white;
    font-weight: bold;
}
.MuiOutlinedInput-input {
    padding: 9px !important;
    background-color: white !important;
}
.login_error {
    color: red;
    text-align: center;
    padding-bottom: 12px;
}
.login_success {
    color: green;
    text-align: center;
    padding-bottom: 12px;
}
.MuiInputLabel-outlined {
    margin-top: -5px;
  }
  .MuiOutlinedInput-inputMarginDense {
    padding-top: 10.5px !important;
    padding-bottom: 10.5px !important;
  }
#signInButton:disabled {
    background-color: grey;
}