.secondary-button {
    background-color: #cd4c4c !important;
    margin-left: 5px !important
}

/* .opentok-container {
    width: 60%;
    display: flex;
} */

.container {
    display: flex;
    justify-content: center;
    align-items: center;
}



.agent-conatiner {
    background: #E0E8E8 0% 0% no-repeat padding-box;
    height: 100%;
}

.agent-call-header {
    display: flex;
    height: 84px;
    /* min-width: 1400px; */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;


}

.agent-call-header .header-left-section {
    flex: .5;
    padding: 10px 50px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.agent-call-header .header-right-section {
    flex: .5;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.call-green-image {
    width: 17px;
    height: 17px;
    opacity: 1;
    transform: matrix(-0.07, -1, 1, -0.07, 0, 0);
}

.call-green-image-wrapper {
    width: 31px;
    height: 31px;
    border-radius: 100px;
    background: #72D4D1 0% 0% no-repeat padding-box;
    opacity: 1;
}

.call-green-image-pre-wrapper {
    width: 42px;
    height: 42px;
    border-radius: 100px;
    background: #D3F1F0 0% 0% no-repeat padding-box;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.opentok-header-labels {
    padding: 0 10px;
}

.opentok-header-labels>.header-label {

    text-align: left;
    font-size: 22px;
    line-height: 27px;
    font-family: GooglesansMedium;
    letter-spacing: 0px;
    color: #191919;
}

.opentok-header-labels span {
    text-align: left;
    font-size: 14px;
    line-height: 17px;
    font-family: GooglesansMedium;

    letter-spacing: 0px;
    color: #484848;
    opacity: 1;
    display: block;
}

.stepper-conatiner {
    width: 100%;
}

.stepper-conatiner>.MuiStepper-root {
    padding: 0;
}

.completed-check-circle {
    width: 32px;
    height: 32px;
    border: 1px solid #05AAA5;
    border-radius: 100px;
}

.check-circle {
    border-radius: 100px;
    width: 32px;
    height: 32px;
    background: #C1D6D6 0% 0% no-repeat padding-box;
    color: #FFF;
}

.active-check-circle {
    background: #05AAA5 0% 0% no-repeat padding-box;
}

.opentok-container {
    display: flex;
    flex: 1;
    width: 100%;
    background: #E0E8E8 0% 0% no-repeat padding-box;
}

.opentok-left-container {
    flex: 0.5;
    padding: 50px;
    min-width: 680px;
}

.video-call-container {
    width: 612px;
    height: 622px;
    border-radius: 30px;
    background-color: #000000;

}

.opentok-right-container {
    flex: 0.5;
    background-color: #EFF9F9;
    width: 100%;
    /* min-width: 668px; */
    /* margin: 0 50px; */
}

.video-footer {
    height: 83px;
    width: 100%;
    display: flex;
    background: #05AAA5 0% 0% no-repeat padding-box;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
}

.disconnect-call {
    width: 69px;
    height: 69px;
    border-radius: 100px;
    background: #FF1517 0% 0% no-repeat padding-box;
    /* box-shadow: 0px 20px 40px #00000080; */
    opacity: 1;
}

.disconnect-call-pre {
    width: 93px;
    height: 93px;
    background: #000000 0% 0% no-repeat padding-box;
    /* box-shadow: 0px 20px 40px #00000080; */
    opacity: 1;
    border-radius: 100px;
    position: absolute;
    margin-top: 540px;
}

.footer-time-display {
    text-align: left;
    margin-top: 40px;
    font-size: 16px;
    line-height: 20px;
    font-family: GooglesansMedium;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.footer-icons {
    display: flex;
    justify-content: flex-end;
}

.round-tool-icon {
    width: 46px;
    height: 46px;
    background: transparent;
    color: #FFF;
    border-radius: 100px;
    margin-right: 10px;
    cursor: pointer;
}

.round-tool-icon-pressed {
    background: #C1D6D6 0% 0% no-repeat padding-box;
}

.tools-icon {
    width: 20px;
    height: 13px;
}

.flex-p5 {
    flex: 0.5;
}
.subscriber-view{
    position: absolute;
    top: 57%;
    left: 60px;
}
.publisher-view {
    width: 113px;
    height: 125px;
    box-shadow: 0px 10px 15px #00000033;
    border: 6px solid #FFFFFF;
    border-radius: 10px;
    opacity: 1;
    /* position: absolute; */
    bottom: 100px;
    left: 60px
}

.subscriber {
    flex: 1;
    height: 539px;
    width: 612px;
}

.publisher {

}

.OTSubscriberContainer {
    flex: 1;
}


.steps-render-container {
    display: flex;
    width: 100%;
    padding: 10px 0 0px 20px;
    justify-content: center;
}

.aadhar-component {
    flex : 1;
    padding: 0 30px;
    max-width: 640px;
    /* display: flex; */
}
.aadhar-component-title{
    display: flex;
    flex: 1;
    padding: 20px 0;
}
.aadhar-component-title span {
    text-align: center;
    font-size: 24px;
    line-height: 30px;
    font-family: GooglesansBold;
    letter-spacing: 0px;
    color: #1D2323;
    margin-left: 15px;
}

.round-step {
    width: 41px;
    height: 41px;
    background: #05AAA5 0% 0% no-repeat padding-box;
    border-radius: 100px;
    font-size: 18px;
    line-height: 22px;
    font-family: GooglesansBold;
    font-weight: Bold;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.agent-card {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 15px #425E5D29;
    border-radius: 10px;
}
.agent-detail-card{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 15px #425E5D29;
    border-radius: 10px;
    margin-top: 35px;
}
.agent-card-header {
    display: flex;
    border-bottom: 1px solid #F9F9F9;
    min-height: 40px;
}

.agent-detail-card-header {
    display: flex;
padding-top: 15px;
background: #C9EFEE;
min-height: 57px;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
justify-content: space-between;
align-items: center;
}

.card-header-text{
    margin: 0 15px;
    text-align: left;
    font-size: 18px;
    line-height: 40px;
    font-family: GooglesansMedium;
    font-weight: Bold;
    letter-spacing: 0px;
    color: #646B6B;
    cursor: pointer;
}

.detail-card-header-text{
    margin: 0 15px;
    text-align: left;
    font-size: 18px;
    line-height: 40px;
    font-family: GooglesansMedium;
    font-weight: Bold;
    letter-spacing: 0px;
    color: #646B6B;
}
.active-card-header-text{
    color: #05AAA5;
    border-bottom: 3px solid #048C88;
    font-family: GooglesansBold;

}

.agent-card-body {
    padding:20px;
    min-height: 200px;

    /* display: flex; */
}
.agent-card-body2 {
    padding:20px;
    min-height: 200px;
    display: flex;
}
.agent-left-card{
    flex :1
}
.agent-right-card{
    flex:1
}
.agent-left-card-captured{
    width: 93%;
    height: 300px;
    display: flex;
    margin: auto;
    border-radius: 10px;
    background: #F9F9F9 0% 0% no-repeat padding-box;
    color: #484848;
    font-size: 16px;
    line-height: 18px;
    font-family: GooglesansBold;
    font-weight: Bold;
    letter-spacing: 0px;
}

.document-capture-button {
    padding: 10px 20px !important;
    font-size: 16px !important;
    line-height: 18px !important;
    font-family: GooglesansMedium !important;
    color: #05AAA5 !important;
    border: 1px solid #048C88 !important;
    text-transform: capitalize !important;
    margin: 10px !important;
    width: 130px !important;
}

.detail-card-header-text{
    font-size: 18px;
    line-height: 18px;
    font-family: GooglesansBold;
    font-weight: Bold;
    color: #1D2323;
}

.detail-left-card{
    flex:0.4;
    display: flex;
}
.detail-right-card{
    flex:0.6
}
.detail-left-card > .detail-round-view > .aadhar-image {
    width: 85px;
    height: 85px;
    background: #A5E4E2 0% 0% no-repeat padding-box;
    border-radius: 100px;
    margin-left: 15%;
}

.detail-left-card > .detail-round-view > span{
    /* margin-top: 30px; */
    font-size: 17px;
    line-height: 45px;
    font-family: GooglesansMedium;
    color: #484848;
}

.details-label{
    font-size: 14px;
    line-height: 30px;
    font-family: GooglesansMedium;
    color: #484848;
}

.details-text-info{
    font-size: 17px;
    line-height: 18px;
    font-family: GooglesansMedium;
    color: #484848;
}

.aadhar-details-info > span{
    display: block;
    font-family: GooglesansMedium
}
.aadhar-details-info {
    width: 100%;
}

.details-hairline{
    height: 0;
    border-bottom: 1px solid #425E5D29;
    margin: -5px 0 1px 0;
}

.hide-hairline{
    border-bottom: 1px solid transparent;
}

.document-next-button {
    background-color : #048C88 !important;
    color : #FFFFFF !important;
    box-shadow: 0px 8px 20px #00000033 !important;
    padding: 8px 30px !important; 
    font-family: GooglesansMedium

}
.document-back-button{
    border-color : transparent !important;
    color : #048C88 !important;
    /* box-shadow: 0px 0px 0px #00000033 !important; */
    padding: 8px 30px !important; 
    font-family: GooglesansMedium

}
.document-next-button-wrapper{
    display: flex;
    margin-top: 30px;
    margin-bottom: 50px;
    /* justify-content: flex-end; */
    font-family: GooglesansMedium

}

.h-space{
    flex : 1
}
.sm-pen-round {
    border-radius: 100px;
    border: 1px solid #048C88;
    background-color: #FFFFFF;
    margin: -10px 15px;
    width: 36px;
    height: 36px;
    cursor: pointer;
}
.edit-pen{
    width: 18px;
    height: 18px;
}
.edit-cance-bt-view{
    display: flex;
}

.edit-cance-bt-view > .cance-button {
    margin: 0 10px 5px 10px !important;
    font-size: 14px !important;
    background-color: #FFFFFF;
    line-height: 18px !important;
    font-family: GooglesansMedium !important;
    color: #05AAA5 !important;
    border: 1px solid #048C88 !important;
    text-transform: capitalize !important;
}
.edit-cance-bt-view > .save-button {
    margin: 0 10px 5px 10px !important;
    font-size: 14px !important;
    background-color: #048C88;
    line-height: 18px !important;
    font-family: GooglesansMedium !important;
    color: #ffffff !important;
    text-transform: capitalize !important;
}

.edit-cance-bt-view > .save-button {
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2),0px 0px 0px 0px rgba(0,0,0,0.14),0px 0px 0px 0px rgba(0,0,0,0.12) !important;
    background-color: #048C88 !important;
}
.aadhar-input{
    width: 100%;
    border: none;
    border-color: transparent;
    padding: 8px 0;
}
.name-input{
    width: 100%;
}
.aadhar-input-active{
    border-bottom: 2px solid #048C88; 
  font-family: GooglesansRegular;

}
.aadhar-input-inactive{
    background-color: transparent;
    color: #000000;
}

.text-area {
    /* overflow:hidden; */
    resize: none;
  font-family: GooglesansRegular;

}

.aadhar-loader-wrapper { 
    width: 100%;
}
.radio-view {
    display: flex;
    width: 100%;
    align-items: center;
    height: 50px;
}
.inner-radio-view{
    display: flex;
    cursor: pointer;
    
}

.inner-radio-view > span {
    font-size: 16px;
    line-height: 22px;
    font-family: GooglesansBold;
    color: #484848;
    margin: 0 10px 0 0px;
}
.inner-radio-view > .active-radio {
    fill : #048C88 !important;
    border: 0px #92AFAF solid !important;
}
.inner-radio-view  > .radio {
    margin: 0 10px;
    fill: #FFFFFF;
    border: 1px #92AFAF solid;
    border-radius: 100px;
    height: 18px;
    width: 18px;
}

.uploads-capture-buttons{
    display: flex;
}
.space-20{
    height: 20px;
}
.flex1 {
    flex : 1
}
.profile-container {
    background-color: #FFFFFF;
    height: 85%;
    width: 80%;
    /* border-radius: 10px; */
    /* max-height: calc(100% - 120px); */
    overflow-y: scroll;
}
/* .modal-body {
    max-height: calc(100% - 120px);
    overflow-y: scroll;
} */
.profile-header{
    height: 80px;
    border-bottom: 1px solid #e2e2e2;
    display: flex;
    align-items: center;
    padding: 0 20px;
}

.profile-header > .profile-header-title {
    font-size: 22px;
    line-height: 27px;
    font-family: GooglesansMedium;
    color: #191919;
    margin-left: 30px;
}

.profile-header > .cancel-modal{
    cursor: pointer;
    fill: #215353 !important;
    height:  32px !important;
    width:  32px !important;
}
.modal-details-section {
    display: flex;

}
.modal-profile-pic-side{
    width: 30%;
}
.modal-details-side{
    width: 70%;
}
.modal-profile-pic-view {
    height: 200px;
    width:  200px;
    border-radius: 100px;
    background: #F6F6F6 0% 0% no-repeat padding-box;
}
.modal-profile-pic-view > span{
    font-size: 22px;
    line-height: 27px;
    font-family: GooglesansMedium;
    color: #AEAEAE;
}
.details-left-side{
    width: 40%;
    /* margin-top: -35px; */
}
.details-right-side{
    width: 60%;
}

 .details-info-view{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 40px;
}
 .details-info-view  > .detail-title {
    font-size: 14px;
    line-height: 17px;
    font-family: GooglesansRegular;
    color: #444444;
    
}
 .details-info-view  > .detail-value {
    font-size: 16px;
    line-height: 20px;
    font-family: GooglesansMedium;
    color: #191919;
    font-weight: bold;
}
.verification-views > .check-status {
    padding: 15px 20px;
    background: #E8FCFB 0% 0% no-repeat padding-box;
    border-radius: 10px;
    width: 40%;
}

.verification-views > .check-status > span{
    font-size: 16px;
    line-height: 20px;
    font-family: GooglesansMedium;
}
.profile-status-check{
    display: flex;
}
.profile-status-check > span {
    font-size: 16px;
    line-height: 20px;
    font-family: GooglesansMedium;
    margin-left: 7px;
}
.profile-status-check > .success {
    color: #15C249;
}
.profile-status-check > .verified{
    color: #15C249;
}
.profile-status-check > .failed {
    color: #FF1517;
}
.profile-status-check > .success-icon {
    fill : #15C249 !important;
}
.profile-status-check > .failed-icon {
    fill : #FF1517 !important;
}
.width-20{
    width: 20%;
}
.width-10{
    width: 10%;
}
.width-100{
    width: 100%;
}

.face-match-view{
    background: #E8FCFB 0% 0% no-repeat padding-box;
    padding: 20px;
    width: 90%;
    border-radius: 10px;
    margin-top: 30px;
}
.face-match-view > span{
    font-size: 20px;
    line-height: 30px;
    font-family: GooglesansMedium;
}

.face-match-sub-views{
    /* flex-direction: column; */
    display: flex;
}
.face-match-sub-view{
    width: 30%;
}
.face-match-sub-view > span{
    font-size: 16px;
    line-height: 28px;
    font-family: GooglesansMedium;
}
.profile-view-footer-hairline {
    border-bottom: 1px solid #e2e2e2;
}
.padd-b-20{
    padding-bottom: 20px;
}

.profile-footer-buttons{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px;
    margin-top: 20px;
}
.profile-reject-button {
    border-color: #FF1517 !important;
    color: #FF1517 !important;
    margin-right: 20px !important;
    padding: 10px 20px !important;
}
.profile-accept-button {
    background-color: #048C88 !important;
    color: #FFFFFF !important;
    padding: 10px 20px !important;
}
/* body { overflow-y: scroll;
    overflow-y: hidden ;
    overflow-x: hidden; 
} */
.backdrop {
    height : 100%;
    border-radius: 3px;
    z-index: 1;
    background-color: rgba(0,0,0,0.2);
    color: #048C88;
  }
.screencapture {
    display: flex;
    margin-top: 22px;
}
.maindata {
    display: flex !important;
    margin-left: auto !important;
}
/* liveliness */
.liveliness_indicating{
    background-color: #CCFFFE;
    padding: 17px;
    text-align: center;
    border-radius: 10px;
    font-family: GooglesansMedium;
    font-size: 20px;

}
.liveliness_data {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 15px #425E5D29;
    margin-top: 35px;
    padding: 20px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    font-family: GooglesansRegular;
    text-align: center;

}
.livelinessotp{
    background-color: #D9F6F5;
    padding: 33px 50px;
    border-radius: 10px;
    margin: 13px 10px;
    font-size: 38px;
    color: #15605D;
}
.liveliness_indicating img {
    width: 27px;
    border-radius: 50%;
    margin-right: 10px;
}
.agent_screen_capture_loader{
    display: flex;
    justify-content: center;
    padding-top: 17%;
}
.verification_true {
    text-align: center;
    color: green;
    margin-bottom: 1px;
}
.verification_false {
    text-align: center;
    color: red;
    margin-bottom: 1px;
}
.MuiStepLabel-label.MuiStepLabel-active {
    font-family: GooglesansBold;

}
.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    font-family: GooglesansBold;
    font-size: 16px;
}
.aadhar_error {
    color: red;
    font-size: 12px;
    float: left;
}
.profile_image img {
    width: 24px;
}
.textarea textarea{
    margin: 0px 58px;
    border-radius: 10px;
    border: 2px solid rgb(0, 113, 110);
    height: 76px;
}
.saveremarks {
    width: 15% !important;
    margin: auto !important;
}